.boxWrapper {
    height: 100%;
}

.giveFeedBack {
    font-family: Proxima Nova;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 29px;
    margin-bottom: 10px;
}

.description {
    font-family: Brandon Grotesque;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    padding: 10px 0 20px 0;
}

.feedbackInput input {
    font-family: 'Brandon Grotesque';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 29px;
    color: #59617b;
}

.sendFeedback {
    border-radius: 50px;
    border-color: #fa9a09;
    border-style: solid;
    border-width: 1px;
    margin-top: 30px;
    width: 100%;
    height: 50px;
    font-family: Proxima Nova;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
}

@media only screen and (max-width: 567px) {
    .giveFeedBack {
        font-size: 22px;
        line-height: 27px;
    }
}
