.title {
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    color: var(--theme-text-dark-color);
}

.subtitle {
    font-family: 'Brandon Grotesque';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 26px;
    color: var(--theme-label-color);
}

.currentTimezone {
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    color: var(--theme-text-dark-color);
}

.setDefault {
    box-shadow: none;
    background: #fa9a09;
    border-radius: 50px;
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    text-transform: uppercase;
    color: #ffffff;
    width: 100%;
    height: 50px;
}

.chooseTimezone {
    box-shadow: none;
    border: 1px solid #fa9a09;
    background-color: transparent;
    border-radius: 50px;
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    text-transform: uppercase;
    color: #fa9a09;
    width: 100%;
    height: 50px;
}

@media only screen and(max-width: 567px) {
}
