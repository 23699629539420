.xBox {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    position: absolute;
    top: 15px;
    right: 15px;
    cursor: pointer;
}

.x {
    transition: transform 0.2s ease-in-out;
}

.x:hover {
    fill: #ff9a00;
    transform: rotate(90deg);
}
