.title {
    font-family: Proxima Nova;
    font-style: normal;
    font-weight: bold;
    font-size: 27px;
    color: var(--theme-dark-lighter);
}

.subTitle {
    font-family: Brandon Grotesque;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    color: var(--theme-label-color);
    margin-top: 10px;
    margin-bottom: 10px;
}

.instructions {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    border: 1px solid rgba(158, 193, 214, 0.33);
    box-sizing: border-box;
    border-radius: 6px;
    width: 100%;
    height: 100%;
}

.instructionsTitleBox {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    padding: 15px;
    border-bottom: 1px solid #dfebf1;
    width: 100%;
}

.instructionsTitle {
    font-family: Brandon Grotesque;
    font-style: normal;
    font-size: 24px;
    font-weight: bold;
    color: var(--theme-dark-lighter);
}

.instructionsTitleBoxChildren {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    padding: 15px;
}

.instructionsTitleChildren {
    font-family: Brandon Grotesque;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: var(--theme-label-color);
    padding-top: 7px;
}

.controls {
    display: flex;
    width: 100%;
    margin-top: 10px;
    align-items: center;
    justify-content: space-between;
}

.nextButton {
    background: #fa9a09;
    border-radius: 50px;
    color: #fff;
    font-family: Proxima Nova;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    text-align: center;
    text-transform: uppercase;
    width: 150px;
    height: 50px;
}

.nextButton:hover {
    background-color: #fa9a09;
}

.backButton {
    border-color: #fa9a09;
    border-radius: 50px;
    border-style: solid;
    border-width: 1px;
    background-color: transparent;
    color: #fa9a09;
    font-family: Proxima Nova;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    text-align: center;
    text-transform: uppercase;
    width: 150px;
    height: 50px;
}

.pagination {
    background: #ff9a00;
    border-radius: 10px;
    width: 16px;
    height: 6px;
}

.pagination:hover {
    transform: scale(1.2);
}

.inactivePagination:hover {
    transform: scale(1.2);
}

.inactivePagination {
    background: rgba(255, 154, 0, 0.2);
    border-radius: 10px;
    width: 16px;
    height: 6px;
}

.svgs {
    width: 13px;
    height: 13px;
}

@media only screen and (max-width: 567px) {
    .controls {
        display: flex;
        width: 100%;
        margin-top: 10px;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        flex-wrap: wrap;
        gap: 15px;
        margin: 15px 0px;
    }
    .nextButton {
        width: 140px;
    }
    .backButton {
        width: 140px;
    }
    .title {
        font-size: 22px;
    }
    .subTitle {
        font-size: 17px;
    }
    .instructionsTitle {
        font-size: 20px;
    }
    .instructionsTitleChildren {
        font-size: 16px;
    }
    .svgs {
        min-width: 15px;
        min-height: 15px;
    }
}
