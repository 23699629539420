.title {
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    margin-bottom: 10px;
}

.subTitle {
    font-family: 'Brandon Grotesque';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 26px;
}

.userContainer {
    background-color: #fffbf5;
    padding: 20px;
    width: 100%;
    margin-top: 20px;
    display: flex;
    justify-content: space-between;

    .viewProfile {
        font-family: Brandon Grotesque;
        font-size: 15px;
        font-weight: 500;
        line-height: 21px;
        letter-spacing: 0em;
        text-align: left;
        color: #fa9a09;
    }

    > div {
        display: flex;
        gap: 20px;
    }

    .name {
        font-family: Brandon Grotesque;
        font-size: 16px;
        font-weight: 600;
        line-height: 23px;
        letter-spacing: 0em;
        text-align: left;
    }

    .avatar {
        width: 50px;
        height: 50px;
    }

    .userDetails {
        display: flex;
        flex-direction: column;
    }

    .sessionsComplete {
        font-family: Brandon Grotesque;
        font-size: 16px;
        font-weight: 400;
        line-height: 23px;
        letter-spacing: 0em;
        text-align: left;
        color: #59617b;
    }

    .rating {
        display: flex;
        gap: 5px;
        align-items: center;
        border: 1px solid #fac300;
        padding: 0 10px;
        justify-content: center;
        font-family: Brandon Grotesque;
        font-size: 14px;
        font-weight: 600;
        line-height: 20px;
        letter-spacing: 0em;
        text-align: center;
        color: #fac300;
        border-radius: 3px;
    }
}

.actionsContainer {
    display: flex;
    width: 100%;
    gap: 20px;
    margin-top: 20px;
}

.decline {
    font-family: Proxima Nova;
    font-size: 21px;
    font-weight: 700;
    line-height: 26px;
    letter-spacing: 0em;
    text-align: center;
    box-shadow: unset;
    width: 100%;
    border-radius: 50px;
    height: 50px;
}

.accept {
    font-family: Proxima Nova;
    font-size: 21px;
    line-height: 26px;
    font-weight: 700;
    letter-spacing: 0em;
    text-align: center;
    color: #ffffff;
    box-shadow: unset;
    width: 100%;
    border-radius: 50px;
    height: 50px;
}

@media only screen and (max-width: 567px) {
    .title {
        font-size: 22px;
        line-height: 27px;
    }
    .subTitle {
        font-size: 17px;
        line-height: 20px;
    }
    .userContainer {
        flex-direction: column;
        gap: 10px;

        .avatar {
            height: 40px;
            width: 40px;
        }

        .viewProfile {
            margin-left: 60px;
        }
    }
    .actionsContainer {
        gap: 10px;
    }
    .accept {
        font-size: 15px;
        line-height: 20px;
        height: 40px;
    }
    .decline {
        font-size: 15px;
        line-height: 20px;
        height: 40px;
    }
}
