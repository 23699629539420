.title {
    text-align: center;
    font-family: Proxima Nova;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 29px;
    text-transform: capitalize;
    margin-bottom: 16px;
}

.description {
    font-family: Brandon Grotesque;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    font-size: 18px;
    text-align: left;
    width: 100%;
    margin-bottom: 30px;
}

@media only screen and (max-width: 576px) {
    .title {
        font-size: 22px;
        line-height: 27px;
        margin-bottom: 8px;
    }
    .description {
        font-size: 16px;
        line-height: 21px;
        margin-bottom: 25px;
    }
}

:global {
    .swiper-pagination {
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
    }

    .swiper-pagination-bullet {
        width: 20px;
        height: 20px;
        font-family: Proxima Nova;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        color: #ff9a00;
        background: #ff9a0033;
        opacity: 0.7;
        box-shadow: 4px 0px 18px 3px rgba(0, 0, 0, 0.08);
    }

    .swiper-pagination-bullet-active {
        color: #fff;
        background: #ff9a00;
        opacity: 1;
    }

    .swiper-button-prev {
        color: #ff9a00;
        background-color: #fff7eb;
        border-radius: 50px;
        border: 2px solid #ff9a0033;
        width: 35px;
        height: 35px;
        &::after {
            font-size: 12px;
            font-weight: bold;
        }
    }

    .swiper-button-next {
        color: #ff9a00;
        background-color: #fff7eb;
        border-radius: 50px;
        border: 2px solid #ff9a0033;
        width: 35px;
        height: 35px;
        &::after {
            font-size: 12px;
            font-weight: bold;
        }
    }
}
