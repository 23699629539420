.title {
    font-family: Proxima Nova;
    font-style: normal;
    font-weight: 700;
    line-height: 29px;
    font-size: 24px;
    color: var(--theme-text-dark-color);
    text-transform: capitalize;
}

.areYouSure {
    font-family: Brandon Grotesque;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 26px;
    color: var(--theme-label-color);
}

.otherParticipantName {
    font-family: Brandon Grotesque;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 29px;
    color: var(--theme-text-dark-color);
}

.otherParticipantSessionsCompleted {
    font-family: Brandon Grotesque;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 23px;
    color: var(--theme-label-color);
}

.participantRating {
    border: 1px solid #fac300;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    border-radius: 3px;
    width: 65px;
    padding: 0 5px;
}

.rating {
    font-family: 'Brandon Grotesque';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #fac300;
    overflow: hidden;
    text-overflow: ellipsis;
}

.deleteButton {
    box-shadow: none;
    outline: none;
    background: #fa9a09;
    border-radius: 50px;
    color: #fff;
    width: 217px;
    font-family: Proxima Nova;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    height: 50px;
}

.cancelButton {
    box-shadow: none;
    border-radius: 50px;
    border: 1px solid #fa9a09;
    box-sizing: border-box;
    width: 217px;
    font-family: Proxima Nova;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    height: 50px;
}

.error {
    color: #f14336;
    font-family: Brandon Grotesque;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
}

.eventType {
    font-family: 'Brandon Grotesque';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 20px;
    color: #000000;
}

@media only screen and (max-width: 567px) {
    .title {
        font-size: 22px;
    }
    .areYouSure {
        font-size: 16px;
    }
    .deleteButton {
        font-size: 12px;
        width: 135px;
        height: 40px;
    }
    .cancelButton {
        font-size: 12px;
        width: 135px;
        height: 40px;
    }
}
