.title {
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    margin-bottom: 10px;
}

.subTitle {
    font-family: 'Brandon Grotesque';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 26px;
}

.inviteMember {
    display: flex;
    width: 100%;
    gap: 20px;
    margin-top: 16px;
}

.formError {
    font-family: 'Brandon Grotesque';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
}

.inviteMore {
    box-shadow: unset;
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    text-transform: capitalize;
    color: #ff9a00;
    height: 40px;
    border: unset;
}

.x {
    transition: transform 0.2s ease-in-out;
}

.x:hover {
    fill: #ff9a00;
    transform: rotate(90deg);
}

input::placeholder {
    font-family: 'Brandon Grotesque';
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 24px;
    color: var(--theme-label-color);
    opacity: 0.38;
}

.inviteMore:hover {
    background-color: transparent !important;
    border: unset !important;
}

.actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
    width: 100%;
    margin: 24px 0 0 0;

    .cancel {
        font-family: Proxima Nova;
        font-size: 18px;
        font-weight: 700;
        line-height: 22px;
        letter-spacing: 0em;
        text-align: center;
        box-shadow: unset;
        width: 100%;
        border-radius: 50px;
        height: 50px;
    }

    .save {
        font-family: Proxima Nova;
        font-size: 18px;
        line-height: 22px;
        font-weight: 700;
        letter-spacing: 0em;
        text-align: center;
        color: #ffffff;
        box-shadow: unset;
        width: 100%;
        border-radius: 50px;
        height: 50px;
    }
}

@media only screen and (max-width: 567px) {
    .title {
        font-size: 22px;
        line-height: 27px;
    }
    .subTitle {
        font-size: 17px;
        line-height: 20px;
    }
    .actions {
        gap: 8px;

        .save {
            font-size: 15px;
            line-height: 20px;
            height: 40px;
        }
        .cancel {
            font-size: 15px;
            line-height: 20px;
            height: 40px;
        }
    }
}
