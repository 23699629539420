.snackBar {
    background: var(--theme-windows-background);
    box-shadow: 4px 0px 38px 3px rgba(0, 0, 0, 0.13);
    border-radius: 10px;
    min-height: 100px;
    min-width: 370px;
    width: 370px;
    width: 100%;
}

.snackTitle {
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
}

.snackSubtitle {
    font-family: 'Brandon Grotesque';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: var(--theme-label-color);
    // white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.snackBarContent {
    display: flex;
    gap: 5px;
}

.joinNow {
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    color: #fa9a09;
    background-color: transparent;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 5px;
}

.viewDetails {
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    color: #fa9a09;
    background-color: transparent;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 5px;
    margin-top: 5px;
}

.actions {
    display: flex;
    gap: 15px;
    align-items: center;
}

.x {
    transition: transform 0.2s ease-in-out;
}

.x:hover {
    fill: #ff9a00;
    transform: rotate(90deg);
}

@media only screen and (max-width: 567px) {
    .snackBar {
        min-width: unset;
        width: 340px;
        max-width: 340px;
    }
    .snackSubtitle {
        font-size: 16px;
        line-height: 20px;
    }
    .snackTitle {
        font-size: 18px;
        line-height: 22px;
    }
}
