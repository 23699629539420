.emailNotVerifiedBox {
    padding: 10px 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
}

.emailText {
    font-family: Proxima Nova;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: var(--theme-text-dark-color);
    flex-grow: 1;
    span {
        font-size: 14px;
    }
}

.resendEmail {
    box-shadow: unset;
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #ffffff;
    border-radius: 50px;
    height: 40px;
    min-width: 155px;
    text-transform: capitalize;
}

@media only screen and (max-width: 578px) {
    .emailNotVerifiedBox {
        flex-wrap: wrap;
    }
    .desktopTextContent {
        display: none;
    }
    .emailText {
        font-size: 14px;
        span {
            font-size: 14px;
        }
    }
    .resendEmail {
        height: 35px;
        width: 100%;
        font-size: 16px;
        line-height: 20px;
    }
}
