.title {
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
}

.subTitle {
    font-family: 'Brandon Grotesque';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 26px;
    margin: 8px 0;
}

.session {
    border-width: 2px;
    border-style: solid;
    border-radius: 8px;
    padding: 15px;
    width: 100%;
}

.sessionPeriod {
    font-style: normal;
    font-family: Brandon Grotesque;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
}

.bookSession {
    box-shadow: unset;
    font-family: Proxima Nova;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    height: 40px;
    width: 85px;
    border-radius: 50px;
    color: #fa9a09 !important;
    border: 1px solid #fa9a09 !important;
}

.orWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 16px 0;
    width: 100%;
    gap: 16px;
}

.line {
    width: 100%;
    height: 2px;
    background-color: rgba(255, 154, 0, 0.2);
}

.loadingBox {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.sessionWrapper {
    max-height: 350px;
    height: 350px;
    width: 100%;
    overflow: auto;
    padding-right: 8px;
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.participant {
    display: flex;
    gap: 8px;
    align-items: center;
    margin: 4px 0;

    .participantAvatar {
        height: 20px;
        width: 20px;
    }
}

.input {
    font-family: 'Brandon Grotesque';
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 24px;
}

.or {
    font-family: Brandon Grotesque;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    text-transform: lowercase;
}

.newSession {
    box-shadow: unset;
    font-family: Proxima Nova;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    min-height: 45px;
    width: 100%;
    color: white;
    border-radius: 50px;
    box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.15);
}

.participantFullName {
    font-family: 'Brandon Grotesque';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
}

.snackTitle {
    font-family: Proxima Nova;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 22px;
}

.yesImSure {
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    border-radius: 50px;
    text-transform: uppercase;
    color: #ffffff;
    box-shadow: unset;
    width: 100%;
    height: 50px;
}

.cancel {
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    border-radius: 50px;
    text-transform: uppercase;
    color: #fa9a09;
    box-shadow: unset;
    border: 1px solid #fa9a09;
    background-color: transparent;
    width: 100%;
    height: 50px;
}

@media only screen and (max-width: 578px) {
    .title {
        font-size: 22px;
        line-height: 26px;
    }
    .subTitle {
        font-size: 17px;
        line-height: 21px;
    }
}
