.title {
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    margin-bottom: 10px;
}

.subTitle {
    font-family: 'Brandon Grotesque';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 26px;
}

.actionsContainer {
    display: flex;
    width: 100%;
    gap: 20px;
    margin-top: 20px;
}

.decline {
    font-family: Proxima Nova;
    font-size: 21px;
    font-weight: 700;
    line-height: 26px;
    letter-spacing: 0em;
    text-align: center;
    box-shadow: unset;
    width: 100%;
    border-radius: 50px;
    height: 50px;
}

.accept {
    font-family: Proxima Nova;
    font-size: 21px;
    line-height: 26px;
    font-weight: 700;
    letter-spacing: 0em;
    text-align: center;
    color: #ffffff;
    box-shadow: unset;
    width: 100%;
    border-radius: 50px;
    height: 50px;
}

@media only screen and (max-width: 567px) {
    .title {
        font-size: 22px;
        line-height: 27px;
    }

    .subTitle {
        font-size: 17px;
        line-height: 20px;
    }

    .actionsContainer {
        gap: 10px;
    }

    .accept {
        font-size: 15px;
        line-height: 20px;
        height: 40px;
    }

    .decline {
        font-size: 15px;
        line-height: 20px;
        height: 40px;
    }
}
