:global {
    .autoCompleteInput {
        .MuiAutocomplete-tag {
            background: #fff7eb;
            border-radius: 30px;
            font-family: 'Brandon Grotesque';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: #ff9a00;
        }
    }
}
