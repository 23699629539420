.title {
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    color: var(--theme-text-dark-color);
}

.subTitle {
    font-family: 'Brandon Grotesque';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 26px;
    color: var(--theme-label-color);
}

.actionButtonsBox {
    display: flex;
    height: 100%;
    width: 100%;
    justify-content: space-between;
    align-items: flex-end;
    gap: 10px;
}

.input {
    font-family: 'Brandon Grotesque';
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 24px;
    color: var(--theme-label-color);
}

.session {
    border: 2px solid rgba(255, 154, 0, 0.2);
    border-radius: 8px;
    padding: 15px;
    width: 100%;
    background-color: var(--theme-calendar-session);
}

.sessionPeriod {
    color: var(--theme-label-color);
    font-style: normal;
    font-family: Brandon Grotesque;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
}

.participantFullName {
    font-family: 'Brandon Grotesque';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: var(--theme-label-color);
}

.snackTitle {
    font-family: Proxima Nova;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 22px;
    color: var(--theme-dark-lighter);
}

.yesImSure {
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    border-radius: 50px;
    text-transform: uppercase;
    color: #ffffff;
    box-shadow: unset;
    width: 100%;
    height: 50px;
}

.cancel {
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    border-radius: 50px;
    text-transform: uppercase;
    color: #fa9a09;
    box-shadow: unset;
    border: 1px solid #fa9a09;
    background-color: transparent;
    width: 100%;
    height: 50px;
}
