.timeTracker {
    font-family: Proxima Nova;
    font-style: normal;
    font-weight: bold;
    font-size: 17px;
    color: rgba(0, 0, 0, 0.85);
    text-align: center;
}

.taskBox {
    display: flex;
    flex-direction: column;
    max-width: 120px;
}

.taskName {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    font-family: Brandon Grotesque;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    color: rgba(0, 0, 0, 0.85);
    overflow: hidden;
    text-overflow: ellipsis;
    text-transform: capitalize;
    text-align: center;
}

.skip {
    border-radius: 50px;
    width: 35px;
    height: 35px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
        svg: {
            fill: rgba(250, 154, 9, 0.7);
        }
    }
}

.startTimer {
    border-radius: 50px;
    background: rgba(250, 154, 9, 0.2);
    cursor: pointer;
    width: 35px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
        svg {
            fill: rgba(250, 154, 9, 0.7);
        }
    }
}

.x {
    transition: transform 0.2s ease-in-out;
}

.x:hover {
    fill: #ff9a00;
    transform: rotate(90deg);
}
