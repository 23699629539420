.title {
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    color: var(--theme-text-dark-color);
}

.subTitle {
    font-family: 'Brandon Grotesque';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    color: var(--theme-label-color);
}

.reportButton {
    background: #fa9a09;
    border-radius: 50px;
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    text-transform: uppercase;
    color: #ffffff;
    box-shadow: none;
    width: 100%;
    height: 50px;
}

.subjectInput {
    font-family: 'Brandon Grotesque';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
    color: var(--theme-label-color);
}

@media only screen and (max-width: 567px) {
    .title {
        font-size: 22px;
        line-height: 27px;
    }
    .subTitle {
        font-size: 17px;
        line-height: 20px;
    }
}
