.stepCircle {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 5px;
}

.stepNumber {
    position: absolute;
    text-align: center;
    color: #fff;
    font-family: Proxima Nova;
    font-style: normal;
    font-weight: bold;
    font-size: 22px;
    line-height: 27px;
}

.title {
    font-family: Proxima Nova;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 27px;
}

.subTitle {
    font-family: Proxima Nova;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 22px;
}

.skip {
    padding: 5px 15px 5px 0px;
    color: #ff9a00;
    cursor: pointer;
    font-family: Brandon Grotesque;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 29px;
}

.prev {
    background: transparent;
    border: 1px solid #ff9a00;
    box-sizing: border-box;
    border-radius: 50px;
    font-family: Proxima Nova;
    font-style: normal;
    font-weight: 700;
    outline: none;
    color: #ff9a00;
    cursor: pointer;
    min-width: 101px;
    height: 45px;
    font-size: 18px;
    line-height: 22px;
}

.comingSoon {
    background-color: #ff9a00;
    border-radius: 0px 0px 2px 2px;
    font-family: Proxima Nova;
    font-style: normal;
    font-weight: 600;
    color: #fff;
    margin-left: 10px;
    height: 18px;
    align-items: center;
    display: flex;
    font-size: 12px;
    line-height: 15px;
    padding: 0 10px;
}

.nextPrev {
    display: flex;
    flex-grow: 1;
    justify-content: flex-end;
    gap: 10px;
}

.next {
    background: #ff9a00;
    border: 1px solid #ff9a00;
    box-sizing: border-box;
    border-radius: 50px;
    font-family: Proxima Nova;
    font-style: normal;
    font-weight: 700;
    outline: none;
    cursor: pointer;
    color: #fff;
    min-width: 101px;
    height: 45px;
    font-size: 18px;
    line-height: 22px;
}

.important {
    background-color: #7ed321;
    border-radius: 0px 0px 2px 2px;
    font-family: Proxima Nova;
    font-style: normal;
    font-weight: 600;
    color: #fff;
    margin-left: 10px;
    min-height: 18px;
    align-items: center;
    display: flex;
    line-height: 15px;
    padding: 0 10px;
    font-size: 12px;
    text-align: center;
}

.list {
    font-family: Brandon Grotesque;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 20px;
}

:global {
    .reactour__popover {
        width: 450px !important;
        max-width: 450px !important;
    }
}

@media only screen and (max-width: 567px) {
    .list {
        font-size: 16px;
        line-height: 23px;
    }
    .important {
        font-size: 9px;
        padding: 0 5px;
        line-height: 12px;
    }
    .stepNumber {
        font-size: 18px;
        line-height: 27px;
    }
    .skip {
        font-size: 18px;
        line-height: 26px;
    }
    .title {
        font-size: 18px;
        line-height: 22px;
    }
    .subTitle {
        font-size: 16px;
        line-height: 23px;
    }
    .comingSoon {
        font-size: 10px;
        line-height: 12px;
        padding: 0 5px;
    }
    .next {
        min-width: 89px;
        height: 40px;
        font-size: 15px;
        line-height: 18px;
    }
    .prev {
        min-width: 89px;
        height: 40px;
        font-size: 15px;
        line-height: 18px;
    }

    :global {
        .reactour__popover {
            width: 350px !important;
            max-width: 350px !important;
        }
    }
}
